import React, { useState } from 'react';
import flow from 'lodash.flow';
import {
  IconButton,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { CreateOutlined, Check, Close } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { Mask } from 'admin-base-component-library';

import { setSelectionRangeAtEnd } from '../../../utils/input';
import styles from '../styles';

const EditableQuantityField = ({
  t,
  classes,
  label,
  name,
  values,
  setFieldValue,
  onSubmit,
}) => {
  const [quantity, setQuantity] = useState(() => values.quantityRaw);
  const [isEditing, setIsEditing] = useState(false);

  const getFormattedValue = value => `${Mask.maskCurrencyField(value)} (${values.unit})`;

  const onChange = ({ target }) => (
    setSelectionRangeAtEnd(target, () => {
      setQuantity(Mask.maskCurrencyField(target.value));
    })
  );

  const onConfirm = async () => {
    if (!quantity) return;

    await Promise.all([
      setFieldValue(name, getFormattedValue(quantity)),
      setFieldValue('quantityRaw', Mask.maskCurrencyField(quantity)),
      onSubmit(quantity),
    ]);
    setIsEditing(false);
  };

  const onCancel = () => {
    setQuantity(values.quantityRaw);
    setIsEditing(false);
  };

  return (
    <div className={classes.editableFieldContainer}>
      <div className={classes.info}>
        {!isEditing ? (
          <>
            <b>{`${t(label)}: `}</b>
            {values[name]}
          </>
        ) : (
          <div>
            <TextField
              name={name}
              value={quantity}
              autoFocus
              label={t('{{label}} ({{unit}})', { label, unit: values.unit ?? '' })}
              onChange={onChange}
              fullWidth={false}
              required
              error={!quantity}
              variant="outlined"
            />
            {!quantity && (
              <Typography className={classes.labelError}>{t('Obrigatório')}</Typography>
            )}
          </div>
        )}
      </div>

      {isEditing && (
        <div className={classes.editableFieldActions} style={{ marginBottom: !quantity ? 20 : 0 }}>
          <IconButton disabled={!quantity} onClick={onConfirm}>
            <Check />
          </IconButton>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </div>
      )}

      {!isEditing && (
        <IconButton title={t('Editar quantidade')} onClick={() => setIsEditing(true)}>
          <CreateOutlined />
        </IconButton>
      )}
    </div>
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(EditableQuantityField);
