import { setSelectionRangeAtEnd } from '../../utils/input';

export const filterSupplierSegment = (segments, neighborhoods) => [
  {
    name: 'segment',
    type: 'autocomplete',
    options: segments,
    label: 'Segmento',
    grid: { xs: 6, sm: 4 },
    size: 'small',
  },
  {
    name: 'neighborhoods',
    label: 'Atende o bairro',
    type: 'autocomplete',
    options: neighborhoods.filter(({ value }) => value !== true),
    grid: { xs: 6, sm: 4 },
    size: 'small',
  },

];

export default (segments, neighborhoods, FormNeighborhood) => [
  {
    name: 'name',
    label: 'Nome da empresa',
    grid: {
      xs: 12, md: 6,
    },
  },
  {
    name: 'contactName',
    label: 'Nome do representante',
    required: true,
    grid: {
      xs: 12, md: 6,
    },
  },
  {
    name: 'phone',
    label: 'Telefone',
    format: 'phone',
    required: true,
    grid: {
      xs: 12, md: 5,
    },
  },
  {
    name: 'email',
    label: 'E-mail',
    format: 'email',
    readOnly: data => !!data?.email,
    grid: {
      xs: 12, md: 7,
    },
  },
  {
    name: 'minimumValue',
    label: 'Pedido mínimo (R$)',
    format: 'currency',
    onFieldChange: () => setSelectionRangeAtEnd('minimumValue'),
    grid: {
      xs: 12, md: 4,
    },
  },
  {
    name: 'quoteTime',
    label: 'Horário para cotação',
    grid: {
      xs: 12, md: 4,
    },
  },
  {
    name: 'orderTime',
    label: 'Horário para pedido',
    grid: {
      xs: 12, md: 4,
    },
  },
  {
    name: 'segments',
    label: 'Segmentos',
    type: 'tags',
    options: segments,
    grid: {
      xs: 12,
    },
  },
  {
    name: 'neighborhoods',
    label: 'Bairros que atende',
    type: 'tags',
    addNewItem: true,
    formAddItem: FormNeighborhood,
    options: neighborhoods,
    handleFilter: async () => neighborhoods,
    grid: {
      xs: 12,
    },
  },
];
