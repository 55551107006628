import React, { useState, useContext } from 'react';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import flow from 'lodash.flow';
import {
  CustomForm,
  CustomTable,
  Alert,
} from 'admin-base-component-library';

import orderData from 'lodash.orderby';

import { formSuppliers } from './form';
import styles from './styles';
import { tableResponseSuppliers } from './table';
import { sendMessageToSupplier } from '../../services/whatsapp';
import { USER_ROLES } from '../../services/user';
import UserContext from '../../context/UserContext';


const ALERT_MESSAGES = {
  ERROR: {
    type: 'error',
    title: 'Erro',
    getText: failedSuppliers => `Não foi possível enviar a mensagem para nenhum fornecedor. Verifique os telefones e tente novamente.<br/> Fornecedores com falha: <br/>${failedSuppliers.join(', ')}`,
  },
  WARNING: {
    type: 'warning',
    getText: failedSuppliers => `Ocorreu um erro ao enviar a mensagem para alguns fornecedores. Verifique os telefones e tente novamente.<br/> Fornecedores com falha: <br/>${failedSuppliers.join(', ')}`,
  },
  SUCCESS: {
    type: 'success',
    title: 'Sucesso',
    text: 'Todas os orçamentos foram enviadas com sucesso.',
  },
};

const OrderBudgetReportSupliers = ({
  classes,
  suppliers,
  responses = 0,
  params,
  restaurantName,
}) => {
  const [failedSuppliers, setFailedSuppliers] = useState([]);

  const { user } = useContext(UserContext);
  const isAdmin = user?.type === USER_ROLES.admin;

  const sendBudgetToAllSuppliers = async () => {
    const failed = [];

    await Promise.all(
      suppliers.map(async (supplier) => {
        try {
          await sendMessageToSupplier({
            restaurantName,
            supplierPhone: supplier.phone,
            supplierName: supplier.contactName,
            budgetId: params.id,
          });

          setFailedSuppliers(current => current.filter(id => id !== supplier.id));
        } catch (error) {
          setFailedSuppliers(current => (current.includes(supplier.id)
            ? current
            : [...current, supplier.id]),
          );
          failed.push(supplier.name);
        }
      }),
    );

    let messageType = ALERT_MESSAGES.SUCCESS;

    if (failed.length > 0) {
      messageType = failed.length === suppliers.length
        ? ALERT_MESSAGES.ERROR
        : ALERT_MESSAGES.WARNING;
    }

    const {
      type, title, getText, text,
    } = messageType;

    Alert({
      type,
      title,
      text: getText ? getText(failed) : text,
    });
  };

  const sendBudgetToSupplier = async (row) => {
    try {
      await sendMessageToSupplier({
        restaurantName,
        supplierPhone: row.phone,
        supplierName: row.contactName,
        budgetId: params.id,
      });

      setFailedSuppliers(current => current.filter(id => id !== row.id));
      Alert({
        type: 'success',
        title: 'Sucesso',
        text: `Mensagem enviada com sucesso para o fornecedor ${row.name}.`,
      });
    } catch (error) {
      setFailedSuppliers(current => (current.includes(row.id)
        ? current
        : [...current, row.id]),
      );
      Alert({
        type: 'error',
        title: 'Erro',
        text: `Não foi possível enviar a mensagem ao fornecedor ${row.name}. Verifique o telefone cadastrado e tente novamente.`,
      });
    }
  };

  const Table = () => (
    <CustomTable
      data={suppliers}
      pagination={false}
      tableCells={tableResponseSuppliers({
        failedSuppliers,
        sendBudgetToSupplier,
        isAdmin,
      })}
      sortData={(order, orderBy, values) => {
        if (order) {
          return orderData(values, [orderBy], [order]);
        }
        return values;
      }}
      maxHeight="70vh"
      isVertical
      numberedItems
    />
  );

  return (
    <div className={classes.root}>
      <CustomForm
        fields={formSuppliers(
          Table,
          sendBudgetToAllSuppliers,
          responses,
          suppliers?.length ?? 0,
          isAdmin,
        )}
        buttonCancel={false}
        buttonOk={false}
      />
    </div>
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(OrderBudgetReportSupliers);
