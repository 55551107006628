import moment from 'moment';
import firebase from 'firebase';
import { FirebaseService, Exceptions } from 'admin-base-component-library';
import { getRestaurantByID } from '../restaurant';
import { getProductsByCode } from '../product';

export const ITEMS_PER_PAGE = 50;

export const BUDGET_STATUS = {
  OPEN: 'open',
  CANCELLED: 'cancelled',
  FINALIZED: 'finalized',
  SAVED: 'saved',
  REQUESTED: 'requested',
  SENT: 'sent',
};

export const getStatusLabel = (status) => {
  const listStatus = {
    [BUDGET_STATUS.OPEN]: 'Aberto',
    [BUDGET_STATUS.CANCELLED]: 'Cancelado',
    [BUDGET_STATUS.FINALIZED]: 'Finalizado',
    [BUDGET_STATUS.SAVED]: 'Salvo',
    [BUDGET_STATUS.REQUESTED]: 'Solicitado',
    [BUDGET_STATUS.SENT]: 'Enviado',
  };

  return listStatus[status];
};

export const createBudget = async ({
  restaurantId,
  restaurantSuppliers,
  name,
  status,
  observation,
  deliveryTime,
  products,
  endsAt,
  idMainBudget,
}) => {
  const fetchProducts = (await getProductsByCode(
    products.map(({ productId }) => productId),
  )).reduce((allProducts, product) => ({
    ...allProducts,
    [product.id]: product,
  }), {});

  const fetchSupplier = await FirebaseService.getDataList({
    collectionName: 'supplier',
  });

  const suppliersBySegment = fetchSupplier.reduce((suppliersSegments, supplier) => {
    if (!supplier.segments) {
      return suppliersSegments;
    }

    return Object.keys(supplier.segments).reduce((segments, segment) => ({
      ...segments,
      [segment]: [
        ...(segments[segment] || []),
        supplier.id,
      ],
    }), suppliersSegments);
  }, {});


  const suppliers = new Set();
  const noSuppliers = [];
  const formatProducts = products.map((product) => {
    const dataProduct = fetchProducts[product.productId] || product;
    let productHasSuppliers = false;
    dataProduct.segments.forEach((segment) => {
      const restaurantSuppliersBySegment = suppliersBySegment[segment]?.filter(
        item => restaurantSuppliers.includes(item),
      );
      const productDisabledSuppliers = dataProduct.disabledSuppliers.filter(
        item => restaurantSuppliersBySegment.includes(item),
      );
      if (!suppliersBySegment[segment]
        || productDisabledSuppliers.length === restaurantSuppliersBySegment.length
      ) {
        return;
      }
      productHasSuppliers = true;
      suppliersBySegment[segment].forEach((supplier) => {
        if (dataProduct.disabledSuppliers.indexOf(supplier) === -1
        && restaurantSuppliers.indexOf(supplier) > -1) {
          suppliers.add(supplier);
        }
      });
    }, []);

    if (!productHasSuppliers) {
      noSuppliers.push(dataProduct.name);
    }

    return {
      ...product,
      disabledSuppliers: dataProduct.disabledSuppliers || {},
      segments: dataProduct.segments,
    };
  });

  if (idMainBudget) {
    const data = {
      restaurantId,
      name,
      observation,
      status,
      deliveryTime,
      idMainBudget,
      products: formatProducts,
      isTemplate: false,
      endsAt: new Date(endsAt),
      suppliers: [...suppliers],
    };

    const { id } = await FirebaseService.create('childBudget', data);
    return { noSuppliers, budgetId: id };
  }

  const data = {
    restaurantId,
    name,
    observation,
    status,
    deliveryTime,
    deadline: status === BUDGET_STATUS.OPEN && new Date(moment().add(2, 'days')),
    sendEmail: false,
    products: formatProducts,
    isTemplate: false,
    endsAt: new Date(endsAt),
    suppliers: [...suppliers],
  };
  const { id } = await FirebaseService.create('budget', data);
  return { noSuppliers, budgetId: id };
};

export const updateBudget = async ({
  budgetId,
  restaurantId,
  restaurantSuppliers,
  name,
  status,
  observation,
  deliveryTime,
  products,
  endsAt,
}, updateCreatedAt = false) => {
  const fetchProducts = (await getProductsByCode(
    products.map(({ productId }) => productId),
  )).reduce((allProducts, product) => ({
    ...allProducts,
    [product.id]: product,
  }), {});

  const fetchSupplier = await FirebaseService.getDataList({
    collectionName: 'supplier',
  });

  const suppliersBySegment = fetchSupplier.reduce((suppliersSegments, supplier) => {
    if (!supplier.segments) {
      return suppliersSegments;
    }

    return Object.keys(supplier.segments).reduce((segments, segment) => ({
      ...segments,
      [segment]: [
        ...(segments[segment] || []),
        supplier.id,
      ],
    }), suppliersSegments);
  }, {});


  const suppliers = new Set();
  const noSuppliers = [];
  const formatProducts = products.map((product) => {
    const dataProduct = fetchProducts[product.productId] || product;
    let productHasSuppliers = false;
    dataProduct.segments.forEach((segment) => {
      const restaurantSuppliersBySegment = suppliersBySegment[segment]?.filter(
        item => restaurantSuppliers.includes(item),
      );
      const productDisabledSuppliers = dataProduct.disabledSuppliers.filter(
        item => restaurantSuppliersBySegment.includes(item),
      );
      if (!suppliersBySegment[segment]
        || productDisabledSuppliers.length === restaurantSuppliersBySegment.length
      ) {
        return;
      }
      productHasSuppliers = true;
      suppliersBySegment[segment].forEach((supplier) => {
        if (dataProduct.disabledSuppliers.indexOf(supplier) === -1
        && restaurantSuppliers.indexOf(supplier) > -1) {
          suppliers.add(supplier);
        }
      });
    }, []);

    if (!productHasSuppliers) {
      noSuppliers.push(dataProduct.name);
    }

    return {
      ...product,
      disabledSuppliers: dataProduct.disabledSuppliers || {},
      segments: dataProduct.segments,
    };
  });

  const data = {
    restaurantId,
    name,
    observation,
    status,
    deliveryTime,
    deadline: status === BUDGET_STATUS.OPEN && new Date(moment().add(2, 'days')),
    sendEmail: false,
    products: formatProducts,
    endsAt: new Date(endsAt),
    suppliers: [...suppliers],
    ...(updateCreatedAt ? { createdAt: new Date() } : {}),
  };
  await FirebaseService.update('budget', budgetId, data);
  return { noSuppliers, budgetId };
};

export const updateChildBudget = async ({
  budgetId,
  restaurantId,
  restaurantSuppliers,
  name,
  status,
  observation,
  deliveryTime,
  products,
  sentAt,
}) => {
  const fetchProducts = (await getProductsByCode(
    products.map(({ productId }) => productId),
  )).reduce((allProducts, product) => ({
    ...allProducts,
    [product.id]: product,
  }), {});

  const fetchSupplier = await FirebaseService.getDataList({
    collectionName: 'supplier',
  });

  const suppliersBySegment = fetchSupplier.reduce((suppliersSegments, supplier) => (
    !supplier.segments
      ? suppliersSegments : Object.keys(supplier.segments).reduce((segments, segment) => ({
        ...segments,
        [segment]: [
          ...(segments[segment] || []),
          supplier.id,
        ],
      }), suppliersSegments)), {});


  const suppliers = new Set();
  const noSuppliers = [];
  const formatProducts = products.map((product) => {
    const dataProduct = fetchProducts[product.productId] || product;
    let productHasSuppliers = false;
    dataProduct.segments.forEach((segment) => {
      if (!suppliersBySegment[segment]) {
        return;
      }
      productHasSuppliers = true;
      suppliersBySegment[segment].forEach((supplier) => {
        if (dataProduct.disabledSuppliers.indexOf(supplier) === -1
        && restaurantSuppliers.indexOf(supplier) > -1) {
          suppliers.add(supplier);
        }
      });
    }, []);

    if (!productHasSuppliers) {
      noSuppliers.push(dataProduct.name);
    }

    return {
      ...product,
      disabledSuppliers: dataProduct.disabledSuppliers || {},
      segments: dataProduct.segments,
    };
  });

  const data = {
    restaurantId,
    name,
    observation,
    status,
    deliveryTime,
    products: formatProducts,
    isTemplate: false,
    suppliers: [...suppliers],
    ...(!!sentAt) && { sentAt },
  };
  await FirebaseService.update('childBudget', budgetId, data);
  return { noSuppliers, budgetId };
};

export const getBudgetResponsesByBudgetId = async budgetId => FirebaseService.getDataList({
  collectionName: 'budgetResponse',
  filters: [{
    field: 'idBudget',
    type: '==',
    value: budgetId,
  }],
});

export const addBudgetResponse = async ({
  idBudget,
  idSupplier,
  paymentMethod,
  paymentTerm,
  deadline,
  quotationValidity,
  observation,
  products,
  isEmpty = false,
}) => {
  FirebaseService.set('budgetResponse', `${idBudget}-${idSupplier}`, {
    idBudget,
    idSupplier,
    paymentMethod,
    paymentTerm,
    deadline,
    quotationValidity,
    observation,
    products,
    isEmpty,
  });
};

export const updateBudgetResponse = async ({
  idBudget,
  idSupplier,
  paymentMethod,
  paymentTerm,
  deadline,
  quotationValidity,
  observation,
  products,
  isEmpty = false,
}) => {
  await FirebaseService.update('budgetResponse', `${idBudget}-${idSupplier}`, {
    idBudget,
    idSupplier,
    paymentMethod,
    paymentTerm,
    deadline,
    quotationValidity,
    observation,
    products,
    isEmpty,
  });
};

export const sendEmptyBudgetResponse = async ({
  idBudget,
  idSupplier,
}) => {
  await FirebaseService.update('budgetResponse', `${idBudget}-${idSupplier}`, {
    idBudget,
    idSupplier,
    isEmpty: true,
  });
};

export const getBudgets = async ({
  page, ordering, filter, restaurant,
}) => {
  const filters = [];

  if (filter?.restaurantId) {
    filters.push({
      field: 'restaurantId', type: '==', value: filter.restaurantId,
    });
  }

  if (filter?.restaurant?.value) {
    filters.push({
      field: 'restaurantId',
      type: '==',
      value: filter.restaurant.value,
    });
  }

  let responses = null;
  if (filter?.supplierId) {
    filters.push({
      field: 'suppliers', type: 'array-contains', value: filter.supplierId,
    });

    const fetchResponse = await FirebaseService.getDataList({
      collectionName: 'budgetResponse',
      filters: [{ field: 'idSupplier', type: '==', value: filter.supplierId }],
    });

    responses = fetchResponse.reduce((allResponses, response) => ({
      ...allResponses,
      [response.idBudget]: response,
    }), {});
  }

  if (filter?.status) {
    if (Array.isArray(filter.status)) {
      filters.push({ field: 'status', type: 'in', value: filter.status });
    } else {
      filters.push({ field: 'status', type: '==', value: filter.status });
    }
  }

  let orderBy = ordering?.orderBy;
  if (orderBy === 'template') {
    orderBy = 'isTemplate';
  }
  if (orderBy === 'statusLabel') {
    orderBy = filter?.status ? null : 'status';
  }

  const fetchBudget = await FirebaseService.getDataList({
    collectionName: 'budget',
    filters,
    limit: ITEMS_PER_PAGE,
    paginate: true,
    order: orderBy || 'createdAt',
    orderType: ordering?.orderType || 'desc',
    startAfterDoc: page,
  });

  let data = fetchBudget?.data;
  if (responses) {
    data = data.map(budget => ({
      ...budget,
      response: responses[budget.id],
    }));
  }

  if (restaurant) {
    const fetchRestaurant = await FirebaseService.getDataList({
      collectionName: 'restaurant',
    });

    const formatRestaurant = fetchRestaurant.reduce(
      (allRestaurants, currentRestaurant) => ({
        ...allRestaurants,
        [currentRestaurant.id]: currentRestaurant,
      }), {});

    data = data?.map(budget => ({
      ...budget,
      restaurant: formatRestaurant[budget.restaurantId],
    }));

    return { nextDoc: fetchBudget.nextDoc, data };
  }

  return fetchBudget;
};

export const getRecentBudgetResponses = async () => {
  const date = moment().subtract(2, 'days').startOf('day').toDate();

  return FirebaseService.getDataList({
    collectionName: 'budgetResponse',
    filters: [
      {
        field: 'updatedAt',
        type: '>=',
        value: firebase.firestore.Timestamp.fromDate(date),
      },
    ],
  });
};

export const getChildBudget = async ({
  filters,
}) => {
  const fetchBudget = await FirebaseService.getDataList({
    collectionName: 'childBudget',
    filters,
  });
  return fetchBudget;
};

export const getBudgetById = async ({
  budgetId, fetchRestaurant, supplierId, isFranchise, budgetStatus, isParent, isChildBudget,
}) => {
  let budget;
  if (
    (isFranchise
    && budgetStatus !== BUDGET_STATUS.REQUESTED
    && budgetStatus !== BUDGET_STATUS.CANCELLED) || (isParent && isChildBudget)
  ) {
    budget = await FirebaseService.getInstanceById('childBudget', budgetId);
  } else {
    budget = await FirebaseService.getInstanceById('budget', budgetId);
  }

  let response = null;
  if (supplierId) {
    response = await FirebaseService.getInstanceById('budgetResponse', `${budgetId}-${supplierId}`);
  }

  if (fetchRestaurant) {
    const restaurant = await getRestaurantByID(budget.restaurantId);

    return {
      ...budget,
      restaurant,
      response,
    };
  }

  return {
    ...budget,
    response,
  };
};

export const getBudgetByIdWithResponse = async (
  budgetId,
  restaurantId,
  isFranchise,
) => {
  const budget = await FirebaseService.getInstanceById('budget', budgetId);

  if (budget.restaurantId !== restaurantId && !isFranchise) {
    throw new Exceptions.RenderException('Erro ao carregar orçamento');
  }

  const responses = await FirebaseService.getDataList({
    collectionName: 'budgetResponse',
    filters: [{
      field: 'idBudget',
      type: '==',
      value: budgetId,
    }],
  });

  return {
    budget,
    responses,
  };
};

export const getChildBudgetByIdWithResponse = async ({ budgetId, restaurantId }) => {
  const budget = await FirebaseService.getInstanceById('childBudget', budgetId);

  if (budget.restaurantId !== restaurantId) {
    throw new Exceptions.RenderException('Erro ao carregar orçamento');
  }

  const responses = await FirebaseService.getDataList({
    collectionName: 'budgetResponse',
    filters: [{
      field: 'idBudget',
      type: '==',
      value: budgetId,
    }],
  });

  return {
    budget,
    responses,
  };
};

export const removeBudget = async budget => (
  FirebaseService.update('budget', budget.id, {
    status: BUDGET_STATUS.CANCELLED,
  })
);

export const setIsTemplate = async budget => (
  FirebaseService.update('budget', budget.id, {
    isTemplate: !budget.isTemplate,
  })
);

export const finishBudget = budget => (
  FirebaseService.update('budget', budget.id, {
    status: BUDGET_STATUS.FINALIZED,
  })
);

export const deleteChildBudget = async (budget) => {
  await FirebaseService.remove('childBudget', budget.id);
};

export const updateBudgetProducts = async ({
  budgetId,
  products,
  isChildBudget,
}) => (
  FirebaseService.update(isChildBudget ? 'childBudget' : 'budget', budgetId, {
    products,
  })
);
