import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import {
  withStyles,
  CircularProgress,
  Button,
} from '@material-ui/core';

import moment from 'moment';
import { withTranslation } from 'react-i18next';
import flow from 'lodash.flow';
import {
  Alert,
  Exceptions,
  Tabs,
} from 'admin-base-component-library';
import ErrorIcon from '@material-ui/icons/Error';
import UserContext from '../../context/UserContext';
import OrderBudgetReportGeneral from './OrderBudgetReportGeneral';
import OrderBudgetReportSupliers from './OrderBudgetReportSupliers';
import styles from './styles';
import palette from '../../theme/palette';
import {
  BUDGET_STATUS,
  getBudgetByIdWithResponse,
  getChildBudgetByIdWithResponse,
  getChildBudget,
  getBudgetById,
  getBudgetResponsesByBudgetId,
} from '../../services/budget';
import { getSuppliers } from '../../services/supplier';
import { getRestaurantByID } from '../../services/restaurant';
import {
  numberToFloat,
  numberToCurrency,
  TimestampToDateTime,
} from '../../services/Service';
import { USER_ROLES } from '../../services/user';
import OrderChildBudgetReportGeneral from './OrderChildBudgetReportGeneral';
import FranchiseesRestaurant from './Tabs/FranchiseesRestaurant';

const OrderBudgetReport = ({
  t,
  classes,
  history,
  match: { params },
}) => {
  const { user } = useContext(UserContext);
  const isPostQuantityChange = useRef(false);
  const [loading, setLoading] = useState(true);
  const [budget, setBudget] = useState();
  const [suppliersData, setSuppliersData] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [total, setTotal] = useState({});
  const [alertClickChangeSupplier, setAlertClickChangeSupplier] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [childBudgets, setChildBudgets] = useState([]);
  const [suppliersMinVal, setSuppliersMinVal] = useState(null);
  const [mainBudgetId, setMainBudgetId] = useState(null);
  const [mainBudgetStatus, setMainBudgetStatus] = useState(null);
  const [mainRestaurantId, setMainRestaurantId] = useState(null);
  const [franchiseesRestaurantView, setFranchiseesRestaurantView] = useState(false);
  const [type, setType] = useState(null);
  const [childHasDeficit, setChildHasDeficit] = useState(false);
  const [childDeficitSuppliers, setChildDeficitSuppliers] = useState([]);
  const [mainBudgetResponses, setMainBudgetResponses] = useState([]);

  const getSupplierResponseDate = (response) => {
    const responseDate = TimestampToDateTime(response.updatedAt);
    return response?.isEmpty
      ? t('Resposta vazia - {{responseDate}}', { responseDate })
      : responseDate;
  };

  const getSuppliersAndBudget = async () => {
    if (isPostQuantityChange.current === true) return;
    const fetchSuppliers = await getSuppliers({});
    const supplierData = budget.suppliers.reduce((acc, supplier) => {
      const resultSupplier = fetchSuppliers.find(item => item.id === supplier);
      if (resultSupplier) {
        const bugetResponse = budget.responses.find(
          item => item.idSupplier === resultSupplier.id,
        );
        return [
          ...acc,
          {
            ...resultSupplier,
            updatedAt: bugetResponse?.updatedAt
              ? getSupplierResponseDate(bugetResponse)
              : t('Sem resposta'),
            response: budget.responses,
            observation: bugetResponse?.observation,
          }];
      }

      return acc;
    }, []);

    setSuppliersData(supplierData);
  };

  const handleSuppliersMinOrder = () => {
    const suppliers = !budget ? {} : budget.products.reduce((acc, product) => (product.selected ? {
      ...acc,
      [product.selected.supplier.id]: !acc[product.selected.supplier.id]
        ? product.selected.priceTotalValue
        : acc[product.selected.supplier.id] + product.selected.priceTotalValue,
    } : acc), {});

    const notEnoughBought = !budget ? [] : suppliersData.map(sup => (
      {
        id: sup.id,
        name: sup.name,
        amount: numberToFloat(sup.minimumValue) - (suppliers?.[sup.id] || 0),
        hasSelectedProduct: budget.products.some(p => p?.selected?.supplier?.id === sup.id),
      }
    ), []);

    return notEnoughBought.length > 0
      ? setSuppliersMinVal(notEnoughBought) : setSuppliersMinVal(null);
  };

  const getLowerPrice = (priceLower, selectedProduct, currentProduct) => {
    if (priceLower && priceLower < currentProduct.priceTotalValue) {
      return selectedProduct.lower;
    }
    return !!currentProduct?.brand ? selectedProduct.lower : currentProduct;
  };

  const formatChildBudgetProducts = (childBudget = []) => childBudget.reduce((acc, current) => {
    current.products.forEach((item) => {
      const findProductIndex = acc.findIndex(product => product.productId === item.productId);

      if (findProductIndex > -1) {
        acc[findProductIndex] = {
          ...acc[findProductIndex],
          quantity: numberToCurrency(
            numberToFloat(acc[findProductIndex].quantity) + numberToFloat(item.quantity),
          ),
          observation: `${acc[findProductIndex].observation}, ${item.observation ? `${item.observation}` : ''}`,
        };
        return;
      }
      acc.push(item);
    });
    return [
      ...acc,
    ];
  }, []);

  const formatProductResponses = async (responses) => {
    const fetchSuppliers = (await getSuppliers()).reduce((suppliers, supplier) => ({
      ...suppliers,
      [supplier.id]: supplier,
    }), {});

    return responses.reduce((allProducts, response) => ({
      ...allProducts,
      ...response.products.reduce((products, product) => ({
        ...products,
        [product.id]: [
          ...(products[product.id] || []),
          {
            idSupplier: response.idSupplier,
            supplier: fetchSuppliers[response.idSupplier],
            priceTotalValue: numberToFloat(product.priceTotal),
            response,
            ...product,
          },
        ],
      }), allProducts),
    }), {});
  };

  const getChildBudgetResponsesData = (responses, budgetData) => responses.map(response => ({
    ...response,
    products: response.products.map((productResponse) => {
      const currentProduct = budgetData
        ?.products
        ?.find(budgetProduct => budgetProduct.productId === productResponse.id);

      if (!currentProduct) {
        return productResponse;
      }

      return {
        ...productResponse,
        priceTotal: numberToCurrency(
          numberToFloat(currentProduct.quantity)
                * numberToFloat(productResponse.unitPrice),
        ),
      };
    }),
  }));

  const calculateProducts = async (budgetData, mainResponses = null, products = null) => {
    const isFranchise = user?.type === USER_ROLES.franchise;

    const childBudgetResponse = getChildBudgetResponsesData(
      mainResponses || mainBudgetResponses || [],
      budgetData,
    );

    const budgetResponses = isFranchise && childBudgetResponse.length
      ? childBudgetResponse
      : budgetData.responses;

    const response = await formatProductResponses(budgetResponses);
    const formattedProducts = products ?? formatChildBudgetProducts(childBudgets);

    return budgetData.products.map((product) => {
      const findProduct = formattedProducts?.find(
        current => current.productId === product.productId,
      );

      return {
        ...product,
        quantity: findProduct?.quantity || product.quantity,
        responses: response[product.productId] || [],
        ...(response[product.productId] || [])
          .reduce((selectedResponse, currentResponse) => {
            // Ignore response if it's empty
            if (currentResponse?.response?.isEmpty) {
              return selectedResponse;
            }

            const selectedLowest = selectedResponse?.lowerBrand;
            const priceLowest = selectedLowest?.priceTotalValue;
            const selectedLower = selectedResponse?.lower;
            const priceLower = selectedLower?.priceTotalValue;

            // Lower price quotation that doesn't have a specified brand
            const lower = getLowerPrice(priceLower, selectedResponse, currentResponse);

            // Lower price quotation ignoring brand check
            const lowerBrand = (priceLowest && priceLowest < currentResponse.priceTotalValue)
              ? selectedResponse.lowerBrand : currentResponse;

            const priceHigh = selectedResponse?.high?.priceTotalValue || 0;
            const high = (priceHigh < currentResponse.priceTotalValue)
              ? currentResponse : selectedResponse.high;

            const currentSelected = product.selected?.idSupplier
              ? selectedResponse.selected
              : null;

            const selected = (product.selected?.idSupplier === currentResponse.idSupplier)
              ? currentResponse : currentSelected;

            return {
              selected: (product.selected?.idSupplier === -1
                ? product.selected
                : selected || lower || lowerBrand),
              high,
              lower,
              lowerBrand,
            };
          }, {
            selected: null,
            lowerBrand: null,
            lower: null,
            high: null,
          }),
      };
    });
  };

  const postQuantityChange = async () => {
    isPostQuantityChange.current = true;
    const isFranchise = user?.type === USER_ROLES.franchise;
    setIsParent(history?.location?.state?.isParent);
    const restaurantId = user?.restaurant?.id;
    const _mainBudgetResponses = [];

    const fetchBudget = await getBudgetByIdWithResponse(
      params.id,
      restaurantId,
      isFranchise,
    );

    const loadAlertClickChangeSupplier = await localStorage.getItem('alertClickChangeSupplier');
    setAlertClickChangeSupplier(!loadAlertClickChangeSupplier);

    const _budget = {
      ...fetchBudget.budget,
      responses: fetchBudget.responses,
    };

    const products = await calculateProducts(
      _budget,
      _mainBudgetResponses,
      null,
    );

    setBudget({
      ...fetchBudget.budget,
      products,
      responses: fetchBudget.responses,
    });
  };

  const onQuantityChangeFinish = () => {
    isPostQuantityChange.current = false;
  };

  const loadData = async () => {
    try {
      const budgetStatus = history?.location?.state?.status;
      const hasIdMainBudget = history?.location?.state?.idMainBudget;
      const isFranchise = user?.type === USER_ROLES.franchise;
      const isAdmin = user?.type === USER_ROLES.admin;
      setIsParent(history?.location?.state?.isParent);
      let restaurantId = user?.restaurant?.id;
      const _mainBudgetResponses = [];

      if (history.location?.state?.franchiseesRestaurantView) {
        setFranchiseesRestaurantView(true);
      }

      if (history.location?.state?.type) {
        setType(history.location?.state?.type);
      }

      if (history.location?.state?.idMainBudget) {
        const mainBudget = await getBudgetById({
          budgetId: history.location?.state?.idMainBudget,
        });

        setMainRestaurantId(mainBudget.restaurantId);
        setMainBudgetId(history.location?.state?.idMainBudget);
        setMainBudgetStatus(mainBudget.status);

        if (isFranchise && mainBudget.status === BUDGET_STATUS.FINALIZED) {
          const budgetResponses = await getBudgetResponsesByBudgetId(
            history.location.state.idMainBudget,
          );
          _mainBudgetResponses.push(...(budgetResponses || []));
        }
      }

      if (isAdmin) {
        const { restaurantId: paramsRestaurantId } = params;
        restaurantId = paramsRestaurantId;
      }

      let fetchBudget;

      if (isFranchise && budgetStatus === BUDGET_STATUS.REQUESTED) {
        restaurantId = user.restaurant.parent;

        fetchBudget = await getBudgetByIdWithResponse(
          params.id,
          restaurantId,
        );
      } else if (
        (isFranchise
          && budgetStatus !== BUDGET_STATUS.REQUESTED
          && hasIdMainBudget) || history?.location?.state?.isParent
      ) {
        restaurantId = history?.location?.state?.restaurantId || user?.restaurant?.id;

        fetchBudget = await getChildBudgetByIdWithResponse({
          budgetId: params.id,
          restaurantId,
        });
      } else {
        fetchBudget = await getBudgetByIdWithResponse(
          params.id,
          restaurantId,
          isFranchise,
        );
      }

      const loadAlertClickChangeSupplier = await localStorage.getItem('alertClickChangeSupplier');
      setAlertClickChangeSupplier(!loadAlertClickChangeSupplier);

      const fetchRestaurant = await getRestaurantByID(restaurantId);
      setRestaurant(fetchRestaurant);
      setMainBudgetResponses(_mainBudgetResponses || []);

      const childBudgetResponse = getChildBudgetResponsesData(
        _mainBudgetResponses || [],
        fetchBudget.budget,
      );

      const budgetResponses = isFranchise && childBudgetResponse.length
        ? childBudgetResponse
        : fetchBudget.responses;

      const childBudget = await getChildBudget({
        filters: [
          { field: 'idMainBudget', type: '==', value: params.id },
          { field: 'status', type: '==', value: BUDGET_STATUS.SENT },
        ],
      });
      setChildBudgets(childBudget);
      const formattedProducts = formatChildBudgetProducts(childBudget);

      const _budget = {
        ...fetchBudget.budget,
        responses: budgetResponses,
      };

      const products = await calculateProducts(
        _budget,
        _mainBudgetResponses,
        formattedProducts,
      );

      setBudget({
        ...fetchBudget.budget,
        products,
        responses: budgetResponses,
      });
      setLoading(false);
    } catch (e) {
      if (history.location?.state?.status === 'open' && user?.type === USER_ROLES.franchise) {
        history.push(`/${t('orcamentos')}`);

        Alert({
          title: t('Indisponível'),
          text: t('Você não respondeu esta solicitação.'),
          type: 'info',
        });
        return;
      }

      history.push(`/${t('orcamentos')}`);

      if (e instanceof Exceptions.RenderException) {
        Alert({
          title: t('Erro'),
          text: t(e.message),
          type: 'error',
        });
        return;
      }

      Alert({
        title: t('Erro'),
        text: t('Ocorreu um erro inesperado.'),
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (isPostQuantityChange.current === true) return;
    const getDataInit = async () => loadData();

    if (user) {
      getDataInit();
    }
  }, [user]);

  useEffect(() => {
    if (budget) {
      getSuppliersAndBudget();

      const filteredBudgets = budget?.products
        ?.filter(item => item.selected?.idSupplier !== -1) || [];

      const dataTotal = filteredBudgets.reduce((totalAcc, product) => {
        if (!product?.selected) {
          return totalAcc;
        }
        return {
          total: product?.selected?.priceTotalValue + totalAcc?.total,
          lower: product?.lowerBrand?.priceTotalValue + totalAcc?.lower,
          high: product?.high?.priceTotalValue + totalAcc?.high,
          diff: (product?.selected.priceTotalValue - product?.lowerBrand?.priceTotalValue)
            + totalAcc?.diff,
          economize: (product?.high?.priceTotalValue - product?.selected?.priceTotalValue)
            + totalAcc?.economize,
          suppliers: totalAcc?.suppliers.add(product?.selected?.idSupplier),
        };
      }, {
        total: 0,
        lower: 0,
        high: 0,
        diff: 0,
        economize: 0,
        suppliers: new Set(),
      });

      setTotal({
        total: numberToCurrency(dataTotal?.total),
        suppliers: dataTotal?.suppliers?.size,
        lower: numberToCurrency(dataTotal?.lower),
        diff: numberToCurrency(dataTotal?.diff),
        high: numberToCurrency(dataTotal?.high),
        economize: numberToCurrency(dataTotal?.economize),
        responses: budget.responses?.length ?? 0,
      });
    }
  }, [budget]);

  useEffect(() => {
    handleSuppliersMinOrder();
  }, [budget, suppliersData]);


  const LabelTable = (label, color) => (
    <span style={{ color }}>{label}</span>
  );

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const infoDataFields = [
    {
      label: 'Data limite',
      value: moment(new Date(budget.endsAt.seconds * 1000)).format('DD/MM/YYYY HH:mm'),
      grid: { xs: 12, md: 6 },
    },
    {
      label: 'Horário de entrega',
      value: budget.deliveryTime,
      grid: { xs: 12, md: 6 },
    },
    {
      label: 'Observação',
      value: budget.observation,
      grid: { xs: 12 },
    },
  ];

  const infoData = {
    title: budget.name,
    fields: infoDataFields,
  };

  const handleNameAlert = (name, supplier) => {
    if (user?.type === USER_ROLES.franchise) {
      return name;
    }
    if (suppliersMinVal && supplier) {
      const supplierMinOrderData = suppliersMinVal.find(item => item.name === supplier);
      const isMissingMinOrder = supplierMinOrderData && supplierMinOrderData.amount > 0;

      if (isMissingMinOrder) {
        return (
          <div className={classes.labelAlertContainer}>
            <ErrorIcon className={classes.labelAlertIcon} color="error" />
            {name}
          </div>
        );
      }
    }

    if (childDeficitSuppliers.find(s => s.name === supplier && s.product.includes(name))) {
      return (
        <div className={classes.labelAlertContainer}>
          <ErrorIcon className={classes.labelAlertIcon} color="error" />
          {name}
        </div>
      );
    }

    return name;
  };

  const handleNameWarning = (name, hasBrand = false) => (hasBrand ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ErrorIcon className={classes.warningIcon} style={{ marginRight: '5px' }} />
      {name}
    </div>
  ) : name);

  const productData = budget.products.map((product, index) => ({
    index,
    id: product.productId,
    name: handleNameAlert(product.productName, product.selected?.supplier?.name),
    observation: product.selected?.observation || product?.observation,
    supplier: handleNameWarning(
      LabelTable(
        product.selected?.supplier?.name || 'Sem cotações',
        product.selected ? null : palette.danger.dark,
      ),
      !!product.selected?.brand && !!product.selected?.supplier?.name,
    ),
    price: LabelTable(
      product.selected?.unitPrice,
      product.selected?.unitPrice !== product.lowerBrand?.unitPrice
        ? palette.danger.dark : palette.success.dark,
    ),
    priceValue: product.selected?.unitPrice || 0,
    totalPrice: product.selected?.priceTotal || 0,
    priceTotalValue: product.selected?.priceTotalValue || 0,
    supplierValue: product.selected?.supplier?.name || '',
    quantity: product.unit ? `${product.quantity} (${product.unit})` : product.quantity,
    lowestPrice: product.selected?.idSupplier !== -1 ? product.lowerBrand?.priceTotal : '',
    highPrice: product.high?.priceTotal,
    diffPrice: product.selected?.idSupplier !== -1 ? numberToCurrency(
        product.selected?.priceTotalValue - product.lowerBrand?.priceTotalValue || 0,
    ) : numberToCurrency(0),
    quotation: product.selected,
    paymentTerm: product.selected?.response?.paymentTerm,
    deadline: product.selected?.response?.deadline,
    validity: product.selected?.response?.quotationValidity,
    ref: product,
  }));

  const handleCloseBtn = () => {
    if (!franchiseesRestaurantView || user.type === USER_ROLES.franchise) {
      return history.push(`/${t('orcamentos')}`);
    }

    setFranchiseesRestaurantView(false);

    const state = {
      idMainBudget: mainBudgetId,
      mainBudgetRestaurantId: mainRestaurantId,
      franchiseesRestaurantView: false,
    };

    if (type === t('editar')) {
      return history.push({
        pathname: `/${t('orcamentos')}/${t('editar')}/${mainBudgetId}/${t('restaurantes-associados')}`,
        state,
      });
    }

    history.push({
      pathname: `/${t('orcamento-resposta')}/${mainBudgetId}/${t('restaurantes-associados')}`,
      state,
    });

    return history.go(0);
  };

  const baseUrl = `/${t('orcamento-resposta')}/${budget.id}`;

  const OrderBudgetComponent = ({ component: Component }) => (
    <Component
      t={t}
      params={params}
      classes={classes}
      history={history}
      infoData={infoData}
      productData={productData}
      budget={budget}
      setBudget={setBudget}
      mainBudgetId={mainBudgetId}
      mainBudgetStatus={mainBudgetStatus}
      calculateProducts={calculateProducts}
      getChildBudgetResponsesData={getChildBudgetResponsesData}
      mainBudgetResponses={mainBudgetResponses}
      loadData={postQuantityChange}
      onQuantityChangeFinish={onQuantityChangeFinish}
      total={total}
      setTotal={setTotal}
      restaurant={restaurant}
      setRestaurant={setRestaurant}
      alertClickChangeSupplier={alertClickChangeSupplier}
      setAlertClickChangeSupplier={setAlertClickChangeSupplier}
      handleCancel={() => history.push(`/${t('orcamentos')}`)}
      loading={loading}
      setLoading={setLoading}
      user={user}
      childBudgets={childBudgets}
      minOrder={suppliersMinVal}
      childHasDeficit={childHasDeficit}
    />
  );

  return (
    <>
      <Tabs
        btnCloseShow={false}
        components={[
          {
            name: 'Geral',
            route: baseUrl,
            screen: (user.type === USER_ROLES.franchise
                && mainBudgetStatus !== BUDGET_STATUS.FINALIZED) || isParent
              ? (<OrderBudgetComponent component={OrderChildBudgetReportGeneral} />)
              : (<OrderBudgetComponent component={OrderBudgetReportGeneral} />),
          },
          {
            name: 'Fornecedores',
            route: `${baseUrl}/${t('fornecedores')}`,
            screen: <OrderBudgetReportSupliers
              handleCancel={() => history.push(`/${t('orcamentos')}`)}
              t={t}
              params={params}
              classes={classes}
              suppliers={suppliersData}
              responses={total?.responses}
              infoData={infoData}
              restaurantName={restaurant?.name}
            />,
          },
          {
            name: 'Restaurantes Associados',
            route: `${baseUrl}/${t('restaurantes-associados')}`,
            hidden: !(budget.status === BUDGET_STATUS.OPEN
              || budget.status === BUDGET_STATUS.FINALIZED)
              || franchiseesRestaurantView
              || !(user?.type === USER_ROLES.master),
            screen: (
              <FranchiseesRestaurant
                t={t}
                params={params}
                classes={classes}
                history={history}
                isParent={user?.type === USER_ROLES.master}
                setLoading={setLoading}
                user={user}
                mainBudgetRestaurantId={mainRestaurantId}
                budget={budget}
                suppliersData={suppliersData}
                setChildHasDeficit={setChildHasDeficit}
                setChildDeficitSuppliers={setChildDeficitSuppliers}
              />
            ),
          },
        ]}
      />

      <div className={classes.closeButtonContainer}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleCloseBtn}
        >
          {t('Fechar')}
        </Button>
      </div>
    </>
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(OrderBudgetReport);
