/**
 * Keep the input cursor at end while user edit the value
 * Mainly focused on fix inputs with formatted values in Android
 *
 * @param {String|HTMLInputElement} target
 * @param {Function|undefined} callback - The callback for input `onChange` event
 *
 * @returns void
 */
export const setSelectionRangeAtEnd = (target, callback) => {
  /** @type {HTMLInputElement} */
  const input = target instanceof HTMLInputElement
    ? target
    : document.querySelector(`input[name="${target}"]`);

  if (typeof callback === 'function') callback();
  if (input) {
    requestAnimationFrame(() => {
      input.setSelectionRange(-1, -1);
    });
  }
};
