import React from 'react';
import { CustomTable, CustomInfo, CustomForm } from 'admin-base-component-library';
import {
  Modal,
  Fade,
  Backdrop,
  Paper,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Alert as AlertMUI } from '@material-ui/lab';
import {
  Close as IconClose,
} from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import orderData from 'lodash.orderby';

import { tableBudgetResponseProductsSelect } from '../table';
import { numberToCurrency, numberToFloat } from '../../../services/Service';
import { USER_ROLES } from '../../../services/user';
import { BUDGET_STATUS } from '../../../services/budget';
import { formatCurrency } from '../../../utils/formatters';
import InfoField from './InfoField';
import { formChangeSupplier } from '../form';
import EditableQuantityField from './EditableQuantityField';

const ChangeSupplierModal = ({
  t,
  modal,
  classes,
  closeModal,
  product,
  handleSelectProduct,
  userPermission,
  budgetStatus,
  user,
  minOrder,
  handleQuantityChange,
}) => {
  const handleNameError = (
    name,
    currentProduct,
    highlight = false,
  ) => (
    !currentProduct?.supplier?.id || (minOrder?.[currentProduct.supplier.id] ?? 0) <= 0
      ? <span data-highlight={highlight}>{name}</span> : (
        <div data-highlight={highlight} className={classes.labelAlertContainer}>
          <Tooltip
            title={t('Faltam {{missing}} para fechar o pedido mínimo de {{supplier}}.', {
              missing: formatCurrency(minOrder[currentProduct.supplier.id]),
              supplier: currentProduct.supplier.name,
            })}
            arrow
          >
            <ErrorIcon className={classes.labelAlertIconSmall} color="error" />
          </Tooltip>
          {name}
        </div>
      )
  );

  const handleNameWarning = name => (!!name ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ErrorIcon className={classes.warningIcon} style={{ marginRight: '5px' }} />
      {name}
    </div>
  ) : name);

  const getSupplierMissingPriceLabel = (currentProduct) => {
    const amount = minOrder?.[currentProduct.supplier.id] ?? 0;
    return amount <= 0
      ? t('Excedido {{exceeded}}', { exceeded: formatCurrency(Math.abs(amount)) })
      : t('Faltam {{missing}}', { missing: formatCurrency(amount) });
  };

  const getData = () => [
    {
      index: -1,
      id: -1,
      supplier: 'Nenhum',
      observation: '',
      brand: '',
      price: '0,00',
      priceTotal: '0,00',
      minOrder: 'N/A',
      diffPrice: '0,00',
      selected: product.ref?.selected.idSupplier === -1,
      paymentTerm: '',
      deadline: '',
      validity: '',
      product: {
        ...product.ref?.responses[0],
        idSupplier: -1,
        brand: '',
        observation: '',
        priceTotal: '0',
        priceTotalValue: 0,
        unitPrice: '',
        supplier: {
          ...product.ref?.responses[0].supplier,
          id: -1,
          name: 'Nenhum',
        },
        response: {
          deadline: '',
        },
      },
    },
    ...product.ref?.responses.reduce((acc, currentProduct, index) => (
      currentProduct?.response?.isEmpty ? acc : [
        ...acc, {
          index,
          id: currentProduct.productId,
          supplier: currentProduct.supplier.name,
          observation: currentProduct.observation,
          brand: handleNameWarning(currentProduct?.brand ?? ''),
          price: currentProduct.unitPrice,
          priceTotal: currentProduct.priceTotal,
          minOrder: handleNameError(
            getSupplierMissingPriceLabel(currentProduct),
            currentProduct,
            product.ref.selected.idSupplier === currentProduct.idSupplier,
          ),
          diffPrice: numberToCurrency(
            Math.abs(product.ref.selected.priceTotalValue - currentProduct.priceTotalValue),
          ),
          selected: product.ref.selected.idSupplier === currentProduct.idSupplier,
          paymentTerm: currentProduct.response.paymentTerm,
          deadline: currentProduct.response.deadline,
          validity: currentProduct.response.quotationValidity,
          product: currentProduct,
        }]), [],
    ),
  ];

  const editableQuantityField = props => (
    <EditableQuantityField {...props} onSubmit={handleQuantityChange} />
  );

  return (
    <Modal
      open={modal}
      className={classes.modalForm}
      onClose={closeModal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <Paper className={classes.modalReport}>
          <div className={classes.modalCloseContaier}>
            <IconButton onClick={closeModal}>
              <IconClose />
            </IconButton>
          </div>

          {user?.type !== USER_ROLES.restaurant ? (
            <CustomInfo
              data={{
                fields: [
                  {
                    label: 'Produto',
                    value: typeof product?.name === 'object' ? product?.name?.props.children[1] : product?.name,
                    grid: { xs: 12, md: 6 },
                  },
                  {
                    label: 'Quantidade',
                    value: product?.quantity,
                    grid: { xs: 12, md: 6 },
                  },
                  {
                    label: 'Observação',
                    value: product?.observation,
                    grid: { xs: 12 },
                  },
                ],
              }}
            />
          ) : (
            <CustomForm
              classes={classes}
              defaultValues={{
                product: typeof product?.name === 'object' ? product?.name?.props.children[1] : product?.name,
                quantity: product?.quantity,
                quantityRaw: product?.ref?.quantity,
                unit: product?.ref?.unit,
                observation: product?.observation,
              }}
              fields={formChangeSupplier(InfoField, editableQuantityField)}
              buttonOk={false}
              buttonCancel={false}
            />
          )}

          <h2 className={classes.title}>{`${t('Produtos')}`}</h2>

          {(user?.type === USER_ROLES.admin || budgetStatus !== BUDGET_STATUS.FINALIZED) && (
            <AlertMUI
              className={classes.alertMui}
              icon={false}
              variant="filled"
              severity="warning"
            >
              {user?.type !== USER_ROLES.admin ? t('A alteração de fornecedores só pode ocorrer em cotações finalizadas.') : t('Usuários administrativos não podem alterar o fornecedor selecionado.')}
            </AlertMUI>
          )}

          {product?.ref?.responses?.some(currentProduct => !!currentProduct?.brand) && (
            <AlertMUI
              className={classes.alertWarning}
              icon={false}
              variant="filled"
              severity="warning"
            >
              {t('Alguns fornecedores cotaram produtos de marcas/gramaturas diferentes das solicitadas.')}
            </AlertMUI>
          )}

          {product?.ref?.responses && (
            <CustomTable
              tableCells={tableBudgetResponseProductsSelect(userPermission, budgetStatus)}
              data={getData()}
              sortData={(order, orderBy, values) => {
                if (order) {
                  const formattedValues = values?.map(value => ({
                    ...value,
                    priceValue: numberToFloat(value?.price),
                    diffPriceValue: numberToFloat(value?.diffPrice),
                  }));

                  const fields = {
                    price: 'priceValue',
                    diffPrice: 'diffPriceValue',
                  };

                  return orderData(formattedValues, [fields[orderBy] || orderBy], [order]);
                }
                return values;
              }}
              pagination={false}
              onClickRow={selected => handleSelectProduct(selected?.product, product)}
              isVertical
            />
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ChangeSupplierModal;
